
.userInfo {
    animation: userInfoAn 1s;
}

@keyframes userInfoAn {
    from {
        opacity: 0;
        transform: translateX(200px)
    }

    to {
        opacity: 1;
        transform: translateX(0)
    }
}