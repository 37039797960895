.token {
    animation: tokenAN 0.5s ease-in-out;
}

@keyframes tokenAN {
    /* from {
        opacity: 0;
    }
    to {
        opacity: 1;
    } */
}