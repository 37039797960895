.changeName{
    animation: nameAN 1s ease-in-out;
}
@keyframes nameAN {
    0% {
        opacity: 0;
        /* transform: translateX(-200px); */
    }
    100% {
        opacity: 1;
        /* transform: translateX(0); */
    }
}
.changePassword{
    animation: passwordAN 1s ease-in-out;
}
@keyframes passwordAN {
    0% {
        opacity: 0;
        /* transform: translateX(200px); */
    }
    100% {
        opacity: 1;
        /* transform: translateX(0); */
    }
}