.link {
  fill: none;
  stroke: #555;
  stroke-opacity: 0.45;
  stroke-width: 1.5px;
}

text {
  font-size: 1rem;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  fill: #fff;
  mix-blend-mode: plus-lighter;
  pointer-events: none;
}