.home {
    animation: homeAn 1s;
}

@keyframes homeAn {
    from {
        opacity: 0;
        transform: translateY(-200px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

.logo {
    animation: logoAn 1s;
}

@keyframes logoAn {
    from {
        opacity: 0;
        transform: translateX(-200px)
    }

    to {
        opacity: 1;
        transform: translateX(0)
    }
}

.loginBtn {
    animation: loginAn 1s;
}

@keyframes loginAn {
    from {
        opacity: 0;
        transform: translateX(200px)
    }

    to {
        opacity: 1;
        transform: translateX(0)
    }
}