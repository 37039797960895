.loop {
    transition: transform 0.5s ease-in-out;
}

.loop.rotate {
    transform: rotate(-180deg);
}

.qrcode {
    transition: opacity 0.5s ease-in-out;
    animation: qrcodeNA 0.5s ease-in-out;
}

@keyframes qrcodeNA {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}