.introduce {
    animation: identifier1 1s;
}

@keyframes identifier1 {
    from {
        opacity: 0;
        transform: translateY(300px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}